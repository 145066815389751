import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routeList";

const AppRouter = () => {

  const RoutesComponent = () => {

    const location = useLocation();

    useEffect(() => {
      const currentRoute = routes?.find(route => route?.path === location?.pathname);
      const metaDescription = document.querySelector('meta[name="description"]');
      if (currentRoute) {
        document.title = currentRoute.title;
      }
      if (metaDescription) {
        metaDescription.setAttribute('content', currentRoute?.description);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = currentRoute?.description;
        document.head.appendChild(meta);
      }
      document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location]);

    return <Routes>
      {routes.map(({ path, element }, index) => {
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  }

  return (
    <BrowserRouter>
      <RoutesComponent />
    </BrowserRouter>
  );
};

export default AppRouter;
