import React from "react";
import "./style.scss";
import { Outline_Filled_Btn, PrimaryBtn, Video } from "../../../components";
import heroVideo from "../../../assets/videos/heroVideo.mp4";
import heroThumbnail from "../../../assets/images/heroBg.png";
// import heroRoundImage from '../../../assets/images/heroRoundImage.png';
import heroImage from "../../../assets/images/serviceman.jpg";
import heroImage1 from "../../../assets/images/hero/download.svg";
const Index = () => {
  // const buttons = [
  //   {
  //     name:"Ebook: Navigation new frontiers in security",
  //     showArrow:true
  //   },
  //   {
  //     name:"Learn more: The Future of AI Security",
  //     showArrow:false
  //   },
  //   {
  //     name:"Modern Security Approaches",
  //     showArrow:true
  //   },
  // ]

  return (
    <div className="hero__main__section">
      {/* <div className='video__container'>
        <Video thumbnail={heroThumbnail} videoUrl={heroVideo} />
      </div> */}

      <div className="custom__container hero__flex__section">
        <div className="content__section">
          <h1>A Better Road Map for AI</h1>
          <p>
            The emergence of AI, or artificial intelligence, has been one of the
            most transformative developments in recent technology. The impact of
            AI is profound and multifaceted, affecting industries ranging from
            healthcare and finance to entertainment and transportation. It has
            the potential to enhance productivity, create new opportunities, and
            solve complex problems. As AI continues to advance, it will likely
            play an increasingly central role in shaping the future.{" "}
          </p>
          <div className="btn__container">
            <PrimaryBtn text={"Top Concerns for CIOs"} />
          </div>
          {/* <div className='action__btns__section'>
            {buttons?.map((v,i)=>{
              return <AnimateButton showArrow={v?.showArrow} text={v?.name} key={i} />
            })}
            
          </div> */}
        </div>
        <div className="image__section">
          <iframe title='lottie' style={{ width: "100%", height: 500 }} src="https://lottie.host/embed/9bf9d394-05b8-4d8c-855f-1e7350242db3/ArouWkj93F.json"></iframe>
          {/* <div className="down__image">
            <img src={heroImage} alt="" />
          </div>
          <div className="upper__image">
            <img src={heroImage1} alt="" />
          </div> */}
        </div>
      </div>
      {/* <div className='curve__image__bg' /> */}
      <div className="absolute__section">
        <div className="center__absoulte__main row">
          <div className="left__absoulte col-lg-3">
            <img src="https://publish-p57963-e462109.adobeaemcloud.com/adobe/dynamicmedia/deliver/dm-aid--12d10100-e791-4ce0-9094-d5b3d7cd9fa3/600x378-build24-hp-featured-card.png?preferwebp=true&quality=85&width=1440" />
          </div>
          <div className="center__absoulte col-lg-7 ">
              <h5>Announcements, "What's New", and More at BUILD 2024</h5>
              <p>
                Ready to push the limits of what you can build in the AI Data
                Cloud? Snowflake executives and product and engineering leaders
                demo the latest advancements.
              </p>
          </div>
          <div className="right__absoulte col-lg-2 d-flex justify-content-center align-items-center">
            <Outline_Filled_Btn text={"WATCH NOW"} classes={"py-3 px-5 rounded-pill w__lg__100"} filled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
