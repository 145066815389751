import React from 'react';
import './style.scss';
import logo from '../../assets/images/Synthlakelogo.png';
import clutch from '../../assets/images/platforms/clutch-r-w.png';
import google from '../../assets/images/platforms/goole-r-w.png';
import { LinkBtn, NewsletterInput } from '..';
import { IoLogoInstagram, IoLogoLinkedin } from "react-icons/io5";
import { FaXTwitter, FaFacebook } from "react-icons/fa6";
import { SiMinutemailer } from "react-icons/si";
import { IoLocationOutline } from "react-icons/io5";
import { LuPhoneCall } from "react-icons/lu";

const Index = () => {

    const reviews = [
        {
            stars: 4.5,
            image: clutch,
            reviews: 50,
            color: "#E62415"
        },
        {
            stars: 4.5,
            image: google,
            reviews: 50,
            color: "#FFC107"
        },
    ]

    const links = [
        {
            title: "Resources",
            links: [
                {
                    name: "snowflake",
                    link: "/snowflake"
                },
                {
                    name: "dbt",
                    link: "/dbt"
                },
                {
                    name: "AWS",
                    link: "/"
                },
                {
                    name: "Azure",
                    link: "/"
                },
                // {
                //     name: "Oracle",
                //     link: "/"
                // },
            ]
        },
        {
            title: "Company",
            links: [
                {
                    name: "About us",
                    link: "/"
                },
                {
                    name: "Case Study",
                    link: "/"
                },
                {
                    name: "Blogs",
                    link: "/"
                },
                {
                    name: "Pricing",
                    link: "/"
                },
                {
                    name: "Contact Us",
                    link: "/"
                },
            ]
        }
    ]

    const socialLinks = [
        {
            icon: <IoLogoLinkedin color='#fff' />,
            name: "Linkedin",
            link: "https://www.linkedin.com/company/synthlake/"
        },
        {
            icon: <FaFacebook color='#fff' />,
            name: "Facebook",
            link: "/"
        },
        {
            icon: <FaXTwitter color='#fff' />,
            name: "Twitter",
            link: "https://x.com/CloudMaxim98069"
        },
        {
            icon: <IoLogoInstagram color='#fff' />,
            name: "Instagram",
            link: "https://www.instagram.com/synth.lake/profilecard/?igsh=cHE2b3l0c20wYjZh"
        },
    ]

    const contactList = [
        {
            icon: <LuPhoneCall size={40} color='#2ea3f2' />,
            title: "India Office",
            linkText: "+91 9346098889",
            link: "tel:+919346098889",
            secondTitle: "USA Office",
            secondLinkText: "+1 (408) 338 9367",
            secondLink: "tel:+14083389367",
            newTab: false
        },
        {
            icon: <IoLocationOutline size={40} color='#2ea3f2' />,
            title: "India Office",
            linkText: "1st Floor, Western Pearl, Hitech City Rd, Kondapur, Hyderabad, Telangana 500084",
            link: "https://www.google.com/maps/place/Egens+Lab/@23.8340712,90.3631117,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c14c8682a473:0xa6c74743d52adb88!8m2!3d23.8340663!4d90.3656866!16s%2Fg%2F11rs9vlwsk?entry=ttu",
            secondLinkText: "Pleasanton Office Suite 4695 Chabot Drive Suite 200 Pleasanton,California 94588",
            secondLink: "",
            secondTitle: "USA Office",
            newTab: true
        },
        {
            icon: <SiMinutemailer size={40} color='#2ea3f2' />,
            title: "Say Hello",
            linkText: "support@synnthai.com",
            link: "mailto:support@synnthai.com",
        },
    ]

    const legalLinks = [
        // {
        //     name: "Support Policy",
        //     link: "/"
        // },
        // {
        //     name: "Terms & Conditions",
        //     link: "/"
        // },
        {
            name: "Privacy Policy",
            link: "/"
        },
    ]

    return (
        <div className='footer__main'>
            <div className='custom__container'>
                <div className='footer__section'>
                    <div className='intro__section'>
                        <img src={logo} alt='synthlake' />
                        <p>
                            Welcome to Synthlake, where innovation meets our passion in a journey that started with a simple idea and a shared dream.
                        </p>
                        <div className='d-flex align-items-center gap-4'>
                            {reviews?.map((v, i) => {
                                return <div className='review__card'>
                                    <img src={v?.image} alt={`review${i}`} />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='links__section__'>
                        <div className='links__section__container'>
                            {links?.map((v, i) => {
                                return <div className='links__section'>
                                    <h4>
                                        {v?.title}
                                    </h4>
                                    <div className='mt-4'>
                                        {v?.links?.map((v, i) => {
                                            return <div className='mt-2 overflow-hidden'>
                                                <LinkBtn name={v?.name} link={v?.link} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='newsletter__container'>
                        <div className='newletter__section'>
                            <h4>
                                Don’t Missed Subscribe
                            </h4>
                            <NewsletterInput />
                            <h5>
                                Social Just You Connected Us!
                            </h5>
                            <div className='social__icons__section'>
                                {socialLinks?.map((v, i) => {
                                    return <a target='_blank' href={v?.link} className='social__link'>
                                        {v?.icon}
                                        <p>
                                            {v?.name}
                                        </p>
                                    </a>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact__list'>
                    {contactList?.map((v, i) => {
                        return <div className='col-lg-3 m-0'>
                            <div className='contact__list__item' key={i}>
                                <div>
                                    {v?.icon}
                                </div>
                                <div>
                                    <p>{v?.title}</p>
                                    <a href={v?.link} target={v?.newTab && "_blank"}>
                                        {v?.linkText}
                                    </a>
                                </div>
                            </div>
                            {v?.secondLinkText &&
                                <div className='contact__list__item mt-4' key={i}>
                                    <div>
                                        {v?.icon}
                                    </div>
                                    <div>
                                        <p>{v?.secondTitle}</p>
                                        <a href={v?.secondLink} target={v?.newTab && "_blank"}>
                                            {v?.secondLinkText}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div >
                <div className='copyright__section'>
                    <p>Copyright 2024 Synthlake</p>
                    <div className='d-flex align-items-center gap-4'>
                        {legalLinks?.map((v, i) => {
                            return <a href={v?.link} key={i} >
                                {v?.name}
                            </a>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index