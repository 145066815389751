import rbac from '../assets/images/inoo - 1.jpg'
import elt from '../assets/images/4944103.jpg'
import migration from '../assets/images/data migrater.jpg'
// import levo from '../assets/images/Levo - Voice Bot_11zon.jpg'
import bot from '../assets/images/Bot.jpg';
import dataGoverner from '../assets/images/Data Governor.jpg'
import korero from '../assets/images/Korero.jpg'




export const rbacAcceleratorData = {
    "Data Migrator": {
        businessChallenge: "Transitioning your data from one source to another seems a simple task. But not for businesses that daily collect big volumes of structured and unstructured data. According to Industry, more than 80% of data migration projects fail to meet deadlines or stick to the budget. One of the top 4 reasons for this happening is that companies neglect data migration challenges and cannot handle the damage these risks bring to their projects. If you are determined to change this tendency and complete your data migration on time and according to your budget, we are there to help.",
        solution: "Automated solution to migrate your data from On-Prem or Cloud or Heterogeneous sources, handles all kinds of data and connects to most of the industry data bases and sources, migrates data on the fly and platform agnostic, can be deployed in major three hyper scalers(aws,azure,gcp). Automated data reconciliation avoids manual intervention and saves time. Automated alerts to notify end users about migration status.",
        impact: "Automation in data migration offers several benefits. It significantly reduces the time and effort required for data migration, leading to cost savings. It also improves the accuracy of data migration by minimizing the risk of human error.",
        btnShow: true,
        image: migration
    },
    "ELT Frame Work": {
        businessChallenge: "Organizations face complex, time-consuming data management issues, especially with traditional ETL processes. Building data pipelines, handling diverse formats, and managing workflows manually often lead to inefficiencies and slow decision-making. Teams must spend significant effort on planning, modelling, and integration, delaying the ability to act on real-time insights.",
        solution: "ETL Frame Work, designed for Snowflake, automates key stages of the data lifecycle, from pipeline planning to data integration. It simplifies ETL processes through a low-code interface, enabling users to extract, load, and transform data with minimal technical expertise. Pre-built templates, automated workflows, and support for diverse datasets help streamline data operations, allowing quicker, more efficient data loading and transformation.",
        impact: "By automating ELT processes, ETL Frame Work reduces operational overhead, allowing faster pipeline deployment and quicker insights. It empowers non-technical users to manage data workflows, enhances scalability, and minimizes manual effort. This results in improved decision-making, optimized data management, and significant time savings for teams across industries.",
        image: elt
    },
    "Data Governor": {
        businessChallenge: "Organizations often encounter a range of data quality issues that can impact decision-making, operational efficiency, and customer satisfaction. Here are some specific data quality issues organizations might face: Inaccuracy, Incomplete Data, Inconsistency, Duplicity, Outdated Data, Unclear Data Definitions, Data Entry Errors, and Lack of Standardization.",
        solution: "Data validation rules and automated checks to catch errors during data entry. Regularly reviews and corrects inaccuracies through data cleansing processes. Establish mandatory fields and data entry requirements. Performs data profiling to identify and fill in missing data. Harmonizes data from different sources. Identifies and merges duplicate records.",
        impact: "Organization can be profound, influencing various aspects of operations, decision-making, and strategic planning. Improves Operational Efficiency, Decision-Making, Customer Experience, Data Integration and Consolidation, Regulatory Compliance, Data Governance, Cost Savings, Risk Management, Scalability, Employee Productivity, Data Integrity and Trustworthiness.",
        image: dataGoverner
    }
}

export const rbacAcceleratorTabs = [
    "Data Migrator",
    "ELT Frame Work",
    "Data Governor",
]


export const dataSharingTabs = [
    "Levo - Voice Bot",
    "Fraud Detector",
    "Korero – AI Cx Expert",
]


export const dataSharingData = {
    "Levo - Voice Bot": {
        businessChallenge: "Industries like insurance, finance, healthcare, and compliance deal with massive document volumes, from legal regulations to customer agreements. Extracting insights or answering specific questions from these documents requires significant manual effort and subject expertise. Information is often fragmented across multiple formats, making it hard to quickly retrieve relevant data. This leads to inefficiencies, delays, and poor customer experience, especially when fast, accurate responses are crucial.",
        solution: "Levo - Voice Bot addresses these challenges by automating document querying and summarization using Retrieval-Augmented Generation (RAG) technology. It enables users to quickly extract information from vast datasets through a low-code, conversational interface. The bot references multiple documents in real-time, provides source hyperlinks, and supports various file formats and calculations. Designed for scalability and customization, Levo - Voice Bot is adaptable for different industries, integrating seamlessly with existing systems and supporting API and multilingual features.",
        impact: "Levo - Voice Bot reduces manual work, enabling faster decision-making and improving customer experience by delivering instant, accurate responses. It enhances compliance and accuracy with verifiable answers while cutting operational costs through automation. Its scalability supports growing data needs without increasing overhead, making it a versatile, cost-effective solution that drives efficiency and boosts customer satisfaction across industries.",
        image: bot
    },
    "Fraud Detector": {
        businessChallenge: "Fraud detection refers to actions set in place to prevent criminals from gaining monetary advantages through false pretences. In the online business world, fraud, scams, and bad agents are damaging in a number of ways. Companies have to put steps in place to ensure that fraud is detected and stopped before it affects business.",
        solution: "Uses machine learning algorithms to analyze behaviours and detect anomalies that indicate fraud. Solution starts with a baseline of normal transaction patterns and user behaviours and then continuously monitor data to look for deviations from the norm",
        impact: "Reduces the costs and consequences businesses face without it. Prevents financial losses, fraudulent activities can cause reputational damage, business interruptions and lost productivity.",
        image: rbac
    },
    "Korero – AI Cx Expert": {
        businessChallenge: "Customer experience (CX) executives face several new realities in customer behaviour and expectations as well as service delivery and outcomes. CX is an enterprise-wide commitment, but often, the relevant customer feedback doesn't make it to the people who need it. If departments like sales, marketing, and support keep separate customer databases, this can create considerable discontinuity to the customer experience.",
        solution: "Solutions is designed to capture and improve Customer Data and Insights, Omnichannel Engagement, Customer Journey Mapping, Personalization and Customization, Customer Feedback and Surveys, and Customer Support and Service. Analyses the customer and agent interactions performs sentiment analysis to provide feedback.",
        impact: "Enhanced Customer Satisfaction, Increased Customer Retention, Improved Brand Reputation, Higher Revenue and Sales. Automation and streamlined processes reduce operational costs and improve the efficiency of customer service operations. Access to comprehensive customer insights allows for more informed strategic decisions and targeted marketing efforts.",
        image: korero
    }
}

export const domain = 'https://synnthai.com'
export const getCanonicalUrl = (routes) => {
    const currentRoute = routes?.find(route => route?.path === window.location.pathname);
    return `${domain}${currentRoute?.path}`
}