import React from 'react';
import './style.scss';
import dbtImg from '../../../assets/images/vscode.jpg';


const Index = () => {
    return (
        <div className='dbt__hero__main'>
            <div className='custom__container'>
                <div className='flex__section'>
                    <div className='text__section'>
                        <h3>
                            dbt Consulting<br />
                            Partner
                        </h3>
                        <p>
                        dbt Preferred Consulting Partner offering development and deployment services for dbt   
                        </p>
                    </div>
                    <div className='img__section'>
                        <img alt='dbt' src={dbtImg} />
                    </div>
                </div>
                <div className="about__section">
                    <h4>
                        Work more quickly and effectively to generate data that the entire company can rely on.

                    </h4>
                    <p>
                        A premier consultancy partner for businesses looking to integrate dbt into their contemporary data stack is Anblicks. Our skilled DBT consultants assist businesses in transforming their data flows into a unified, coherent, and validated data architecture by utilizing best practices in analytics engineering. Anblicks, one of the most seasoned DBT partners in the business, can assist clients in building a strong foundation right away thanks to his extensive production deployment expertise.

                        <br />
                        Our team of professionals can create data transformations, improve transformation pipelines, and manage complicated, slowly changing dimensions, all while focusing on leveraging the most recent best practices to make sure data is prepared for future data demands. Companies that work with Anblicks have access to a helpful staff that is committed to providing the direction and assistance needed to realize the full potential of big data analytics and obtain the data insights needed for well-informed decision-making.

                    </p>
                </div>
            </div>
        </div>
    )
}

export default Index