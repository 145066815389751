import React from 'react';
import './style.scss';
import { IoReturnDownForwardOutline } from "react-icons/io5";



const Index = ({ data }) => {
    return (
        <div className='blog__card__main'>
            <img src={data?.img} alt='blog' />
            <div className='content__section'>
                <h5>{data?.title}
                </h5>
                <p>
                    {data?.description}
                </p>
                <button>
                    Read Full Article <IoReturnDownForwardOutline />
                </button>
            </div>
        </div>
    )
}

export default Index