import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { AboutUs, ChallengesSection, Clients, ConsultingSection, Hero, NumbersSection, Services, Testimonial } from '../../layouts';
import { Helmet } from "react-helmet";
import { getCanonicalUrl } from '../../utils/data';
import { routes } from '../../config/routeList';


const Index = () => {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={getCanonicalUrl(routes)} />
            </Helmet>
            <Navbar />
            <Hero />
            <AboutUs />
            <ConsultingSection />
            <Services />
            <ChallengesSection />
            {/* <CaseStudies /> */}
            <Clients />
            <NumbersSection />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default Index
