import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./style.scss";
function Index() {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <Dropdown
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        show={show}
        className="innovation__dropdown__main"
      >
        <Dropdown.Toggle
          className="bg-transparent border-0"
          id="dropdown-basic"
        >
          Innovation <FaChevronDown size={12} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to={"/innovation"}>Innovation</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to={"/success-stories"}>Success Stories</Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className="mobile__innovation__dropdown">
       <div onClick={() => setShow(!show)} className="d-flex align-items-center justify-content-between">
       <p style={{fontSize:"20px",fontWeight:"600",fontFamily:"Texta"}}>Innovation</p>
       <FaChevronRight size={12} />
       </div>

        {show && (<div className={`innovation__dropdown__list ${show ? "show" : ""}`}>
            <button onClick={() => setShow(!show)}>
                <FaChevronLeft color="#249EDC" size={12} /> <p>Back</p>
            </button>
          <Link className="innovation__dropdown__link" to={"/innovation"}>Innovation</Link>
          <Link className="innovation__dropdown__link" to={"/success-stories"}>Success Stories</Link>
        </div>)}

      </div>
    </>
  );
}

export default Index;
