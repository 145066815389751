import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { SuccessStoriesHero, SuccessStorisBlogs } from '../../layouts';
import { Helmet } from 'react-helmet';
import { getCanonicalUrl } from '../../utils/data';
import { routes } from '../../config/routeList';

const Index = () => {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl(routes)} />
      </Helmet>
      <Navbar />
      <SuccessStoriesHero />
      <SuccessStorisBlogs />
      <Footer />
    </div>
  )
}

export default Index