import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from "react-icons/fa";
import { SiComma } from "react-icons/si";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.scss';


export default function Index({ slides }) {

  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideChange = (e) => {
    setActiveSlide(e.realIndex)
  }

  return (
    <>
      <Swiper
        slidesPerView={2}
        grabCursor={true}
        spaceBetween={30}
        className="testimonial__carousel"
        onRealIndexChange={onSlideChange}
      >
        {slides?.map((v, i) => {
          return <SwiperSlide>
            <div className='testimonial__card'>
              <div className='comma__icon'>
                <SiComma size={40} />
                <SiComma size={40} />
              </div>
              <div className='reviewer'>
                <div>
                  <div className='text-start '>
                    {Array.from({ length: 5 }).map((v, i) => {
                      return <FaStar key={i} color='#F98A17' size={18} />
                    })}
                  </div>
                  <p className='mt-3'>
                    {v?.title}
                  </p>
                </div>
              </div>
              <p className='mt-2'>
                {v?.description}
              </p>
            </div>
          </SwiperSlide>
        })}
      </Swiper>
      <div className='custom__pagination'>
        {slides?.map((v, i) => {
          return <div key={i} className={`bullet__container ${activeSlide === i ? "active__bullet" : ""}`}>
            •
          </div>
        })}
      </div>
    </>
  );
}
