import React from 'react';
import './style.scss';
import testimonialImg from '../../../assets/images/testimonials/jeff2.png';
import { TestomonialCarousel, VideoModal } from '../../../components';
import thumbnail1 from '../../../assets/images/thumbnails/client-pic-a.jpg'
import thumbnail2 from '../../../assets/images/thumbnails/client-pic.jpg'
import { FaCirclePlay } from "react-icons/fa6";


const Index = () => {

    const [videoId, setVideoId] = React.useState(null);

    const testimonials = [
        {
            description: "“Using Snowflake in collaboration with Synthlake has been an outstanding experience. It simplifies viewing, analyzing, and generating reports that deliver valuable insights for the organization's business”",
            // name: "Jeff Lackey",
            title: "Sagar",
            image: testimonialImg
        },
        {
            description: "“Overall, the Snowflake platform stands out for its high scalability and robust security. It offers a range of advanced features and functionalities that empower  our businesses to store, process, and analyze vast amounts of data in real time”",
            // name: "Jeff Lackey",
            title: "Venkatesh ",
            image: testimonialImg
        },
        {
            description: "“This cloud-based platform provides a secure and user-friendly environment for effortlessly sharing information with my customers. Our organization highly values its simplicity and effectiveness ”",
            // name: "Jeff Lackey",
            title: "Preetham singh",
            image: testimonialImg
        },
        {
            description: "“Synthlake offers a comprehensive suite of features, including row-level security, data masking, and secure sharing. Being entirely cloud-based, it ensures seamless accessibility for anyone interested in using it.”",
            // name: "Jeff Lackey",
            title: "Sagar",
            image: testimonialImg
        },
    ]

    const videos = [
        {
            name: "Cina Cleaves",
            video: "SZEflIVnhH8",
            thumbnail: thumbnail2
        },
        {
            name: "Jokvch Marlin",
            video: "SZEflIVnhH8",
            thumbnail: thumbnail1
        }
    ]

    const updateVideoId = (id) => setVideoId(id);
    const closeVdeo = () => setVideoId(null);

    return (
        <div className='testimonial__main'>
            <div className='custom__container'>
                <h4>
                    What our clients say about synthlake.
                </h4>
                <h5>
                    Over 1200+ Satisfied Clients and Growing
                </h5>
                <div className='testimonial__flex__section'>
                    {/* <div className='video__container'>
                       
                    </div> */}
                    <div className='carousel__container'>
                        <TestomonialCarousel slides={testimonials} />
                    </div>
                </div>
            </div>
            {videoId !== null && <VideoModal onClose={closeVdeo} videoId={videoId} />}
        </div>
    )
}

export default Index
