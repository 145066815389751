import React from 'react';
import './style.scss'

const Index = ({ list }) => {
    console.log("List", list)
    return (
        <div className='article__section__main'>
                <div className='flex__section'>
                    {
                        list?.map((v, i) => {
                            return <div className='article__card__main' key={i}>
                                <div className='custom__container'>
                                    <div className='text__section'>
                                        <h2>
                                            {v?.title}
                                        </h2>
                                        {v?.text ? <p>{v?.text}</p> : ""}
                                        {v?.list ?
                                            <ul className={v?.listType === "number" ? "number" : ""}>
                                                {
                                                    v?.list.map((e, i) => {
                                                        const isString = typeof e === 'string';
                                                        return isString ?
                                                            <li dangerouslySetInnerHTML={{ __html: e }} key={i}>

                                                            </li>
                                                            : <li>
                                                                <div key={i}>
                                                                    <h3>{e?.title}</h3>
                                                                    {e?.text ? <p>{e?.text}</p> : ""}
                                                                    {e?.list ? <ul>
                                                                        {
                                                                            e?.list.map((f, i) => {
                                                                                return <li key={i}>
                                                                                    {f}
                                                                                </li>
                                                                            })
                                                                        }
                                                                    </ul> : ""}
                                                                </div>
                                                            </li>
                                                    })
                                                }
                                            </ul>
                                            : ""}
                                        {v?.textAfterList ? <p>{v?.textAfterList}</p> : ""}
                                    </div>
                                    {v?.image ? <div className='col-md-6'>
                                        <img src={v?.image} alt={v?.title} />
                                    </div> : ""}
                                </div>
                            </div>
                        })
                    }
                </div>
        </div>
    )
}

export default Index