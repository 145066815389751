import { DBT, Home, Snowflake, Innovation, SuccessStories, SuccessStoryDetail } from "../pages";

export const routes = [
  {
    path: "/",
    element: (
      <Home />
    ),
    title: "Home | Synth Lake",
  },
  {
    path: "/snowflake",
    element: (
      <Snowflake />
    ),
    title: "Transform Your Data Strategy with Snowflake Solutions- Synthlake",
    description:"Discover Snowflake, the leading cloud data platform that simplifies data management, analytics, and integration. Unlock scalable, secure, and real-time insights to drive business growth and innovation."
  },
  {
    path: "/dbt",
    element: (
      <DBT />
    ),
    title: "Partner with Experts for Seamless DBT Implementation – Synthlake ",
    description:"Partner with expert DBT consultants to accelerate your data transformation journey. We help optimize data pipelines, streamline analytics workflows, and unlock actionable insights with DBT's powerful data modeling capabilities."
  },
  {
    path: "/innovation",
    element: (
      <Innovation />
    ),
    title: "Fueling Growth and Efficiency with Disruptive Innovation- Synthlake",
    description:"Discover the power of innovation to drive business growth, improve efficiency, and stay ahead of the competition. Explore creative solutions and strategies that transform challenges into opportunities for success."
  },
  {
    path: "/success-stories",
    element: (
      <SuccessStories />
    ),
    title: "Success Stories | Synth Lake",
  },
  {
    path: "/success-story/:id",
    element: (
      <SuccessStoryDetail />
    ),
    title: "Success Stories | Synth Lake",
  },
]

export const navigateWithParams = (navigate, path, params) => {
  const queryString = new URLSearchParams(params).toString();
  navigate(`${path}?${queryString}`);
};