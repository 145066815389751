// import { useState } from "react";
// import Dropdown from "react-bootstrap/Dropdown";
// import { FaChevronDown } from "react-icons/fa6";
// import { ImEarth } from "react-icons/im";
// import "./style.scss"
// function Index({ classes }) {
//   const [show, setShow] = useState(false);

//   const laguageArray = [
//     {
//       name: "English",
//     },
//     {
//       name: "Português",
//     },
//     {
//       name: "Italiano",
//     },
//     {
//       name: "한국어",
//     },
//     {
//       name: "日本語",
//     },
//     {
//       name: "Español",
//     },
//     {
//       name: "Deutsch",
//     },
//     {
//       name: "Français",
//     },
//   ];

//   return (
//     <Dropdown
//       onClick={() => setShow(!show)}
//       show={show}
//       className={`${classes ?? ""}`}
//     >
//       <Dropdown.Toggle
//         className={`bg-transparent border-0 text-black d-flex gap-2 align-items-center`}
//         id="dropdown-basic"
//       >
//         <ImEarth size={20} /> <FaChevronDown size={12} />
//       </Dropdown.Toggle>

//       <Dropdown.Menu>
//         {laguageArray?.map((v, i) => {
//           return <Dropdown.Item className="dropdown__item__hover">{v?.name}</Dropdown.Item>;
//         })}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

// export default Index;

import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { ImEarth } from "react-icons/im";
import "./style.scss";

function Index({ classes }) {
  const [show, setShow] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const languageArray = [
    { name: "English" },
    { name: "Português" },
    { name: "Italiano" },
    { name: "한국어" },
    { name: "日本語" },
    { name: "Español" },
    { name: "Deutsch" },
    { name: "Français" },
  ];

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setShow(false); // Close the dropdown after selection
  };

  return (
   <>
    <div className={`language__dropdown `}>
      <Dropdown
        onToggle={(isOpen) => setShow(isOpen)}
        show={show}
        className="custom__dropdown"
      >
        <Dropdown.Toggle
          className="bg-transparent border-0 text-black d-flex gap-2 align-items-center"
          id="dropdown-basic"
        >
          <ImEarth size={20} /> <FaChevronDown size={12} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown__menu__custom">
          <p className="dropdown__title">LANGUAGES</p>
          {languageArray.map((language, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleLanguageSelect(language.name)}
              className={`dropdown__item__hover ${
                selectedLanguage === language.name ? "selected" : ""
              }`}
            >
              {selectedLanguage === language.name && "✔ "}
              {language.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
      <div class="mobile__language__dropDown">
          
          <button onClick={() => setShow(!show)} style={{all:"unset"}} className=" w-100 d-flex align-items-center justify-content-between">
              <ImEarth size={20} />
              <FaChevronRight size={12} />
          </button>
          {
            show && <div className="language__dropdown__list">
              <button onClick={() => setShow(!show)} className="back_button">
                <FaChevronLeft className="icon" size={12} /> <p>Back</p>
              </button>
              {
                languageArray.map((language, index) => (
                  <div key={index} className="d-flex flex-column array__main w-100">
                    <button onClick={() => handleLanguageSelect(language.name)}>
                      <div>
                      {language?.name}</div>
                      <div>
                      {selectedLanguage === language?.name && "✔ "}</div>
                    </button>
                    
                  </div>
                ))
              }
            </div>
          }
      </div>
   </>
  );
}

export default Index;
