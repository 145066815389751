import React from 'react';
import './style.scss';
import heroImg from '../../../assets/images/acle.png';


const Index = () => {
    return (
        <div className='innovation__hero__main'>
            <div className='custom__container'>
                <div className='flex__section'>
                    <div className='text__section'>
                        <h4>
                            Supercharge your Data
                        </h4>
                        <h3>
                            Snowflake Accelerators
                        </h3>
                        <p>
                            Upgrade your Snowflake results with a Synthlake Accelerator
                        </p>
                    </div>
                    <div className='img__section'>
                        <img src={heroImg} alt='hero' />
                    </div>
                </div>
            </div>
            <div className='bottom__lines__container'>
                <div />
                <div className='orange__line' />
            </div>
        </div>
    )
}

export default Index