import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.scss';
import { PrimaryBtn } from '../..';
import heroImg from '../../../assets/images/Services-Select.png'


export default function Index({ slides, textBlue, btnText }) {

  return (
    <>
      <Swiper
        slidesPerView={1}
        grabCursor={true}
        className="full__width__carousel"
      >
        {slides?.map((v, i) => {
          return <SwiperSlide>
            <div className='carousel__card'>
              <div className='custom__container d-flex flex-wrap align-items-center'>
                <div className={`${textBlue ? "text__blue" : ""} content__section`}>
                  <h4>
                    {v?.title}
                  </h4>
                  <p>
                    {v?.description}
                  </p>
                  <PrimaryBtn classes="mt-4 " text={btnText ?? "Our Services"} />
                </div>
                <div className='image__section'>
                  <img src={heroImg} alt='hero' />
                  {/* <iframe title='snowflake' style={{width:"100%",height:500}} src="https://lottie.host/embed/e5057527-ca29-41fa-949b-bb374c7c6ddf/VI2uHK95Wh.json"></iframe> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        })}
      </Swiper>
    </>
  );
}
