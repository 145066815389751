import React, { useEffect, useState } from 'react'
import './style.scss'
import { CustomDropdown, PrimaryBtn } from '../../../components';


const Index = ({ tabs, data, description, heading2, heading1, reverseRow }) => {

    const [currentTab, setCurrentTab] = useState(tabs[0])

    const changeTab = (tab) => setCurrentTab(tab);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

    useEffect(()=>{
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 769)
        })
    },[])

    return (
        <div className='innovation__accelerator'>
            <div className='custom__container'>
                <div className='content__section'>
                    {heading1 && <h4>
                        {heading1}
                    </h4>}
                    {heading2 && <h3>
                        {heading2}
                    </h3>}
                    {description && <p>
                        {description}
                    </p>}
                </div>
                {!isMobile ? <div className='tabs__section'>
                    {tabs?.map((v, i) => {
                        return <button className={v === currentTab ? "active__btn" : ""} onClick={() => changeTab(v)} key={i}>
                            {v}
                        </button>
                    })}
                </div>:
                <div>
                    <div className='mt-3'>
                        <CustomDropdown options={tabs} changeTab={changeTab} />
                    </div>
                </div>
                }
                <div className='tab__detail__section'>
                    <div className={`${reverseRow ? "reverse__row" : ""} flex__section`}>
                        <div className='text__section'>
                            <h3>
                                {currentTab}
                            </h3>
                            <h5>
                                Business Challenge
                            </h5>
                            <p>
                                {data?.[currentTab]?.businessChallenge}
                            </p>
                            <h5>
                                Solution
                            </h5>
                            <p>
                                {data?.[currentTab]?.solution}
                            </p>
                            <h5>
                                Impact
                            </h5>
                            <p>
                                {data?.[currentTab]?.impact}
                            </p>
                            {data?.[currentTab]?.btnShow && <PrimaryBtn classes={"mt-4"} text={"Connect With Us Today"} />}
                        </div>
                        <div className='img__section'>
                            <img src={data?.[currentTab]?.image} alt={data?.[currentTab]?.impact} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index