import { useEffect } from 'react';
import './App.scss';
import AppRouter from './src/config/AppRouter';
import ReactGA from "react-ga4";


function App() {

  useEffect(() => {
    ReactGA.initialize('G-214HRSJTYN')
  },[])

  return (
    <AppRouter />
  );
}

export default App;
