import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.scss';

// import required modules
import { ButtonWithoutOutline, PrimaryBtn } from '../..';
import { useNavigate } from 'react-router-dom';

export default function Index({ slides }) {

    const [slidesToShow, setSlidesToShow] = useState(4);
    const swiperRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            slidesToShow === 3 && swiperRef.current.swiper.slideTo(1);
        }
    }, [])

    useLayoutEffect(() => {
        const resizeEvent = () => {
            if (window.innerWidth < 576) {
                setSlidesToShow(1)
            } else if (window.innerWidth < 768) {
                setSlidesToShow(2)
            } else if (window.innerWidth < 1025) {
                setSlidesToShow(3)
            } else {
                setSlidesToShow(4)
            }
        }
        window.addEventListener('resize', () => {
            resizeEvent()
        })
        resizeEvent()
    }, [])

    return (
        <>
            <Swiper
                slidesPerView={slidesToShow}
                spaceBetween={30}
                navigation={{
                    nextEl: '.custom__swiper__button__next',
                    prevEl: '.custom__swiper__button__prev',
                }}
                className="success__story__carousel"
                ref={swiperRef}
            >
                <SwiperSlide>
                    <div className='first__card'>
                        <h5>
                            Success Stories
                        </h5>
                        <h3>
                            Performant Data <br />Journeys
                        </h3>
                        <p>
                            Explore how our customers have overcome their data obstacles crediting the power of data analytics and cloud technologies.
                        </p>
                        <div>
                            <PrimaryBtn classes={"mt-4 snowflake__btn colored"} text={"Success Stories"} />
                        </div>
                    </div>
                </SwiperSlide>
                {slides?.map((v, i) => {
                    return <SwiperSlide>
                        <div style={{ backgroundImage: `url(${v?.image})` }} className='success__story__card'>
                            <div className='text__section'>
                                <p>
                                    {v?.description}
                                </p>
                                <div>
                                    <ButtonWithoutOutline onClick={() => {
                                        navigate(`/success-story/${v?.id}`)
                                    }} text={"Read Full Article"} />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                })}

            </Swiper>
        </>
    );
}
