import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { SuccessStoriesHero, SuccessStoryArticle } from '../../layouts';
import { cardData } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getCanonicalUrl } from '../../utils/data';
import { routes } from '../../config/routeList';

const Index = () => {

  const param = useParams();
  const id = param.id;

  const story = cardData.find((item) => Number(item.id) === Number(id));

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl(routes)} />
      </Helmet>
      <Navbar />
      <SuccessStoriesHero hideDescription title={story?.title} />
      <SuccessStoryArticle list={story?.list} />
      <Footer />
    </div>
  )
}

export default Index