import React, { useEffect, useState } from "react";
import "./style.scss";
import logo from "../../assets/images/Synthlakelogo.png";
import {
  ButtonWithoutOutline,
  ContactUsModal,
  DropDown,
  Outline_Filled_Btn,
} from "..";
import exploreBtn from "../../assets/images/exploreBtn.png";
import { Link, useLocation } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import InnovationDropdown from "./innovationList";
import { routes } from "../../config/routeList";
import { FaChevronRight } from "react-icons/fa6";

const Index = ({ isAbsolute, snowflakeBg }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [contactModal, setContactModal] = useState(false);

  const updateTitle = (title, description) => {
    const currentRoute = routes?.find(
      (route) => route?.path === location?.pathname
    );
    const metaDescription = document.querySelector('meta[name="description"]');
    if (currentRoute) {
      document.title = title ?? currentRoute.title;
    }
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        description ?? currentRoute?.description
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = description ?? currentRoute?.description;
      document.head.appendChild(meta);
    }
  };

  const tabs = [
    {
      name: "snowflake",
      link: "/snowflake",
    },
    // {
    //   name: "Azure",
    //   link: "/"
    // },
    {
      name: "dbt",
      link: "/dbt",
    },
    {
      name: " Innovation",
      link: "/innovation",
      type: "innovation",
    },
    {
      name: "ContactUs",
      link: "/",
      type: "contact",
    },
  ];

  const toggleMenu = () => setMenuOpen((o) => !o);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1024);
    });
  }, []);

  const onClose = () => {
    setContactModal(false);
    updateTitle(null, null);
  };
  const openModal = () => {
    setContactModal(true);
    setMenuOpen(false);
    updateTitle(
      " Get in Touch with Us Today-synthlake",
      "Have questions or need support? Contact us today! Our team is ready to assist with any inquiries or help you find the right solutions for your business needs"
    );
  };

  return (
    <div
      className={`navbar__main ${isAbsolute ? "isAbsolute" : ""} ${
        snowflakeBg ? "snowflakeBg" : ""
      }`}
    >
      {!isMobile ? (
        <div className="custom__container navbar__flex__section">
          <div className="left__section">
            <div className="logo__section">
              <Link to={"/"}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="nav__tabs__section">
              {tabs?.map((v, i) => {
                return (
                  <>
                    {v?.type === "contact" ? (
                      <ButtonWithoutOutline
                        onClick={openModal}
                        text={v?.name}
                        key={i}
                      />
                    ) : v?.type === "innovation" ? (
                      <InnovationDropdown />
                    ) : (
                      <Link key={i} to={v?.link}>
                        <ButtonWithoutOutline text={v?.name} />
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          </div>

          <div className="right__section">
            {/* <d className="dropdown__main">
              <DropDown classes={"dropdown__btn"} />
            </d> */}
            <d>
              <Outline_Filled_Btn text={"Contact Sales"} outline />
            </d>
            {/* <d>
              <Outline_Filled_Btn text={"Start for free"} filled />
            </d> */}
          </div>
        </div>
      ) : (
        <div className="mob__nav__main">
          <div className="menu__and__logo custom__container py-4">
            <div>
              <Link to={"/"}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div>
              <button onClick={toggleMenu} className="toggle__menu__btn">
                {menuOpen ? (
                  <IoMdClose color="#000" size={40} />
                ) : (
                  <IoIosMenu color="#000" size={40} />
                )}
              </button>
            </div>
          </div>

          <div className="custom__container overflow-hidden">
            <div className={`${menuOpen ? "actie__menu" : ""} open__menu`}>
              {tabs?.map((v, i) => {
                return (
                  <div
                    className="w-100 border border-1 border-top-0 border-start-0 border-end-0 border-black py-3 cursor__pointer"
                    key={i}
                  >
                    {v?.type === "contact" ? (
                      <ButtonWithoutOutline
                        onClick={openModal}
                        text={
                          <div className="d-flex">
                            <p
                              style={{
                                fontWeight: "800",
                                fontSize: "20px",
                                fontFamily: "Texta",
                              }}
                            >
                              {v?.name}
                            </p>
                          </div>
                        }
                        key={i}
                      />
                    ) : v?.type === "innovation" ? (
                      <InnovationDropdown />
                    ) : (
                      <>
                      <Link to={v?.link}>{v?.name}</Link>
                      </>
                    )}

                  </div>
                );
              })}
              <div  className="w-100 border border-1 border-top-0 border-start-0 border-end-0 border-black py-3 cursor__pointer">

              <DropDown />
              </div>
              <Outline_Filled_Btn classes={"mt-3 py-2"} text={"Contact Sales"} outline />

            </div>
          </div>
        </div>
      )}
      <ContactUsModal modalShow={contactModal} onClose={onClose} />
    </div>
  );
};

export default Index;
